import React from 'react';
import { Link } from 'react-router-dom';
import './redirect.js';

const NotFound = () => (
  <div>
    <h1></h1>
    <Link to="/"></Link>

  </div>
);

export default NotFound;