import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import root from './root';
import NotFound from './NotFound';

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={root} status={200}/>
      <Route component={NotFound} status={404} />
    </Switch>
  </Router>
);

render(<App />, document.getElementById('root'));
